.button {
  transition: background-color 200ms ease-in-out;
  border: none;
  border-radius: 3px;
  padding: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background-color: #783cbc;
  cursor: pointer;
}

.button:hover {
  background-color: #804dba;
}
