.iphonex {
  display: flex;
  transform: rotate(0deg);
  transition: all cubic-bezier(0.36, 0.41, 0.38, 1) 0.4s;
  z-index: 10;
  overflow-x: none;
}

.iphonex-dimensions {
  width: 401px;
  height: 832px;
}

.android-dimensions {
  width: 20em;
  height: 40em;
}

.iphonex .front {
  display: flex;
  flex: 1;
  background-color: #292c2d;
  border-radius: 3em;
  margin: 0.2em;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0.1em 0.4em rgba(255, 255, 255, 0.1) inset;
  z-index: 10;
}
.iphonex .screen {
  display: flex;
  flex: 1;
  background-color: #fff;
  margin: 0.4em;
  border-radius: 2.6em;
  border: solid 3px #121415;
  position: relative;
  z-index: 10;
}

.android-max-width {
  max-width: 20;
}

.iphonex-max-width {
  max-width: 381px;
}
.iphonex .front__line {
  background: linear-gradient(to right, #515455, #454748, #515455);
  position: absolute;
  z-index: 8;
  width: 100%;
  height: 0.5em;
  top: 10%;
}
.iphonex .front__line-second {
  top: 50%;
}
.iphonex .screen .screen__view {
  display: flex;
  flex: 1;
  border-radius: 2.4em;
  overflow: hidden;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.iphonex .screen .screen__view .hello {
  font-size: 2.5em;
  color: #fff;
  position: absolute;
  z-index: 60;
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all ease 0.3s;
  transform: translateY(40%);
  font-weight: 600;
}

.iphonex .phoneButtons {
  width: 1em;
  height: 6em;
  position: absolute;
  z-index: 2;
  background: linear-gradient(to bottom, #212324, #2b2e31, #212324);
  box-shadow: 0 0 0.4em rgba(255, 255, 255, 0.1) inset;
  border-radius: 2px;
}
.iphonex .phoneButtons-right {
  right: 0;
  top: 30%;
}
.iphonex .phoneButtons-left {
  left: 0;
  top: 15%;
  height: 3em;
}
.iphonex .phoneButtons-left2 {
  left: 0;
  top: 25%;
  height: 5em;
}
.iphonex .phoneButtons-left3 {
  left: 0;
  top: calc(25% + 6em);
  height: 5em;
}

.iphonex .screen .screen__view .dribbbleLogo {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.7s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.iphonex .screen .screen__view .dribbbleLogo::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.iphonex .screen .screen__view .dribbbleLogo {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-height: 40em) {
  body {
    font-size: 70%;
  }
  .iphonex {
    width: 48vh;
    height: 94vh;
  }
  .title .title__svg svg text {
    font-size: 4em;
  }
}
