.configurator .select-box {
    margin-bottom: 24px;
}

.configurator .select-box:last-child {
    margin-bottom: 0;
}

.configurator .button {
    width: 100%;
}