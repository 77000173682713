@tailwind base;
@tailwind components;
@tailwind utilities;

/* Definition of our SF Pro Text fonts */
/*
These are the font variations we are using:
Regular (400), Semi bold (600), Bold (700), Heavy (800) and Black (900) for SF Pro Text
*/
@font-face {
  font-family: 'SF Pro Text';
  src: url(./assets/fonts/ios/SFProText/SF-Pro-Text-Regular.otf)
    format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url(./assets/fonts/ios/SFProText/SF-Pro-Text-RegularItalic.otf)
    format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url(./assets/fonts/ios/SFProText/SF-Pro-Text-Semibold.otf)
    format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url(./assets/fonts/ios/SFProText/SF-Pro-Text-Bold.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url(./assets/fonts/ios/SFProText/SF-Pro-Text-Heavy.otf)
    format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url(./assets/fonts/ios/SFProText/SF-Pro-Text-Black.otf)
    format('opentype');
  font-weight: 900;
  font-style: normal;
}
/* End of font definitions */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
